<template>
	<div id="bg-login">
		<div class="content-form-login">
			<div class="form-login">
				<form @submit.prevent="guardarUsuario()">
				<div class="logo-label">	
					<img src="../assets/img/logo_secundario_en_fondo_oscuro.svg" alt="logo-increscendo" id="logo-sidebar-menu">
				</div>
				<!-- <div class="user-label">	
					<label for="">Nombre
						<span style="color: red">*</span>
					</label>
					<input type="text" v-model="form.nombre">
				</div> -->
				<!-- <div class="password-label">
					<label for="">Apellido
						<span style="color: red">*</span>
					</label>
					<input type="text" v-model="form.apellido">
				</div> -->
				<div class="password-label">
					<label for="">Correo
						<span style="color: red">*</span>
					</label>
					<input type="text" v-model="form.correo" placeholder="Ingrese su e-mail." required>
				</div>
				<div class="password-label">
					<input type="checkbox" v-model="form.newsletter">
					<label>Deseo recibir notificaciones vía correo electrónico</label><br>
				</div>
				
				<!-- <div class="password-label">
					<label for="">Usuario
						<span style="color: red">*</span>
					</label>
					<input type="text" v-model="form.usuario">
				</div> -->
				<!-- <div class="password-label">
					<div v-for="(sexo, index) in sexos" :key="index">			
						<input type="checkbox" :value="sexo.id" v-model="form.sexo_id" v-on:click= "check_one()">
						<label>{{sexo.nombre}}</label><br>
					</div>
				</div> -->
				<div class="submit-label">
					<button type="submit" :disabled="btnGuardar">
						<span v-if="btnGuardar" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
						<span> {{ btnGuardar ? 'Iniciando...' : 'Empezar Clase Gratis'}}</span>
					</button>
				</div>
				</form>
			</div>
		</div>
	</div>





</template> 
<script>
	import "../assets/css/login.css";
	import GlobalMixin from "../mixins/global-mixin.js";
	export default{
		name:   'SignUp',
		mixins: [GlobalMixin],
		data: () => {
			return {
				args:  [],
				exito: false,
				form:  {}
			}
		},
		methods: {
			guardarUsuario(){
				this.args = {
					url:  '/usuario/usuario_demo/',
					data: this.form,
					arg:  ''
				};
				this._enviarDatos(this.args).then((response) => {
					this.args = []
					if (response.data.exito) {
						var args = {demo : true};
						this.$store.dispatch('login', args).then(() => {
							this.$router.push({name: "Home_app"})
						})
					}
				})
			},
			validarCorreo(){
				this.args = { url: "/usuario/validarCorreo", data: { params: { "correo" : this.form.correo } }}
				this._getLista(this.args).then((response) => {
					if (response.exito) {
						this._notificarError(response.mensaje)
						this.exito = false
					} else{
						this.exito = true
					}
                })
			}
		}
	}
</script>